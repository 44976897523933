import { css } from "@emotion/react";

import {
  colors,
  breakpoints,
} from "@mytutor/shared-react-web-components/lib/theme";

const subjectsBarStyle = css`
  font: inherit;
  background-color: ${colors.neutral1};
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 80px;

  p {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: 0;
    color: ${colors.neutral8};
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  @media (max-width: ${breakpoints.largeDesktop}px) {
    p {
      font-size: 18px;
    }

    .group5 {
      display: none;
    }
  }

  @media (max-width: ${breakpoints.desktop}px) {
    padding: 0 15px;

    p {
      font-size: 15px;
    }
  }
  @media (max-width: ${breakpoints.tablet}px) {
    .group4 {
      display: none;
    }
  }

  @media (max-width: ${breakpoints.phone}px) {
    padding: 0;
    .group2 {
      display: none;
    }
  }

  @media (max-width: ${breakpoints.smallPhone}px) {
    .group3 {
      display: none;
    }
    .group2 {
      display: inherit;
    }
  }
`;

const SubjectsBar = () => (
  <section css={subjectsBarStyle}>
    <a
      className="group1"
      href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/Maths/`}
    >
      <p>Maths</p>
    </a>
    <a
      className="group3"
      href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/Biology/`}
    >
      <p>Biology</p>
    </a>
    <a
      className="group3"
      href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/Chemistry/`}
    >
      <p>Chemistry</p>
    </a>
    <a
      className="group3"
      href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/Physics/`}
    >
      <p>Physics</p>
    </a>
    <a
      className="group2"
      href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/Science/`}
    >
      <p>Science</p>
    </a>
    <a
      className="group1"
      href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/English/`}
    >
      <p>English</p>
    </a>
    <a
      className="group4"
      href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/German/`}
    >
      <p>German</p>
    </a>
    <a
      className="group4"
      href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/Spanish/`}
    >
      <p>Spanish</p>
    </a>
    <a
      className="group4"
      href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/French/`}
    >
      <p>French</p>
    </a>
    <a
      className="group5"
      href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/Economics/`}
    >
      <p>Economics</p>
    </a>
    <a
      className="group5"
      href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/Psychology/`}
    >
      <p>Psychology</p>
    </a>
    <a
      className="group5"
      href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/History/`}
    >
      <p>History</p>
    </a>
  </section>
);

export default SubjectsBar;
